import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import numbro from 'numbro';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Fade from '@material-ui/core/Fade';

import PointHistoryChart from './components/PointHistoryChart';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import styles from './MapPointDialog.module.scss';
import Tooltip from '@material-ui/core/Tooltip';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';

import {
  GET_BASE_DATA,
} from './query';

function request(url) {
  return fetch(url)
    .then((resp) => resp.json())
    .then((data) => data);
}

const formatNumber = (value) => {
  const formattedValue = numbro(value).format({
    thousandSeparated: true,
    mantissa: 2
  });

  return _.replace(formattedValue, ',', '.');
};

export default function MapPointDialog({
  activeModule,
  headerIsVisible,
  isOpen,
  pointInfo,
  onClose = () => { },
  updateClientAppState = () => { },
}) {
  const formatMessage = useFormatMessage();
  const locale = localStorage.getItem('locale') || 'es';
  const latlng = _.get(pointInfo, 'latlng');
  const parsedBBox = _.get(pointInfo, 'parsedBBox');
  const [ruralProperties, setRuralProperties] = useState([]);
  const [chartData, setChartData] = useState(null);
  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      lat: _.first(latlng),
      lng: _.last(latlng),
    },
    skip: !latlng,
  });
  const getTerritoriesOnList = _.get(baseData, 'territoriesOnList');
  const territoryCategoriesList = _.get(baseData, 'territoryCategoriesList');
  const filteredTerritoryCategoriesList = _.filter(territoryCategoriesList, ({ parentId }) => !parentId);

  const bboxString = _.join(parsedBBox, ',');

  const geoserverBaseUrl = 'https://shared-geoserver.mapbiomas.org/geoserver/mapbiomas-alerta/wfs?';
  const wfsParams = [
    'service=WFS',
    'version=2.0.0',
    'request=GetFeature',
    'typeNames=mapbiomas-alerta:mv_sfb_car',
    'count=20',
    'outputFormat=application/json',
    'srsName=EPSG:4326',
    `bbox=${bboxString},EPSG:4326`
  ];


  const parsePointData = (params) => {
    if (!params) {
      return null;
    }

    const { data, legend } = params;

    return _(data)
      .map((classId, key) => {
        if (classId === 0) {
          return null;
        }

        const year = _.last(_.split(key, '_'));
        const classItemData = _.find(legend, { classification: classId, linguage: locale });

        return {
          year,
          ...(classItemData || {})
        };
      })
      .compact()
      .value();
  };

  const generareSeriesItem = (categoryIndex, item, index, color) => {
    if (!item) {
      return null;
    }

    return {
      value: [index, categoryIndex],
      itemStyle: {
        color: color || _.get(item, 'color'),
      },
      data: item
    };
  };

  useEffect(() => {
    if (latlng) {
      const parsedParams = `lat=${_.round(latlng[0], 100)}&lng=${_.round(latlng[1], 10)}`;

      let urls = [
        request(`/downloads/history_point?module=coverage&${parsedParams}`),
        request(`/downloads/history_point?module=fire&${parsedParams}`),
      ];

      if (activeModule === 'sentinel') {
        urls = [...urls, request(`/downloads/history_point?module=sentinel&${parsedParams}`)];
      }

      Promise.all(urls)
        .then((resp) => {
          const coverageData = parsePointData(_.get(resp, '[0]'));
          const fireData = parsePointData(_.get(resp, '[1]'));
          const sentinelData = parsePointData(_.get(resp, '[2]'));

          const years = _.compact(_.uniq(_.map(_.concat(coverageData), 'year')));

          let parsedCoverageData = _.map(years, (year, index) => {
            const coverageItem = _.find(coverageData, { year });
            const fireItem = _.find(fireData, { year });
            const sentinelItem = _.find(sentinelData, { year });
            let coverageItemParsed = generareSeriesItem(0, coverageItem, index);
            let fireItemParsed = generareSeriesItem(1, fireItem, index);
            let sentinelItemParsed = null;

            if (activeModule === 'sentinel') {
              sentinelItemParsed = generareSeriesItem(0, sentinelItem, index);
              coverageItemParsed = generareSeriesItem(1, coverageItem, index);
              fireItemParsed = generareSeriesItem(2, fireItem, index);
            }

            return _.compact([coverageItemParsed]);
          });
          parsedCoverageData = _.flatten(parsedCoverageData);

          let yAxisNames = [
            formatMessage('mapbiomas.map.point_dialog.data_list.coverage'),
          ];

          if (activeModule === 'sentinel') {
            yAxisNames = [
              formatMessage('mapbiomas.map.point_dialog.data_list.coverage'),
            ];
          }

          setChartData({
            categories: years,
            yAxisNames,
            series: [
              {
                name: 'Serie',
                type: 'scatter',
                data: parsedCoverageData
              }
            ]
          });
        });
    }
  }, [latlng]);

  // useEffect(() => {
  //   fetch(`${geoserverBaseUrl}${wfsParams.join('&')}`)
  //     .then((resp) => resp.json())
  //     .then((data) => {
  //       const features = _.get(data, 'features');
  //       const carsList = _.map(features, (feature) => _.get(feature, 'properties'));

  //       setRuralProperties(carsList);
  //     });
  // }, [bboxString]);

  const parsedData = _(filteredTerritoryCategoriesList)
    .map((category) => {
      const territoryData = _.find(getTerritoriesOnList, { categoryId: category.id });

      if (territoryData) {

        const categoryStringList = _.get(category, 'i18nStrings');
        const categorySelectedString = _.find(categoryStringList, { language: locale });

        const territoryStringList = _.get(territoryData, 'i18nStrings');
        const territorySelectedString = _.find(territoryStringList, { language: locale });

        if (territoryData.categoryId == 15 || territoryData.categoryId == 16) {

          return null;

        } else {

          return {
            id: `${_.join(latlng, '-')}-${territoryData.id}`,
            category: territoryData.categoryId,
            category_key: category.key,
            label: _.get(categorySelectedString, 'stringValue'),
            value: _.get(territorySelectedString, 'stringValue'),
          };

        }

      } else {
        return null;
      }
    })
    .compact()
    .value();

  const handleRuralPropertyClick = (ruralPropertyCode) => {
    updateClientAppState({
      ruralPropertyCode: ruralPropertyCode,
    });
    onClose();
  };

  const renderCARs = () => {
    if (_.isEmpty(ruralProperties)) {
      return null;
    }

    return (

      <React.Fragment>
        <h2 className={classnames(styles.titleCar)}>CARs</h2>
        <ul className={styles.dataListCar}>
          {_.map(ruralProperties, (ruralProperty) => {
            return (
              <Tooltip title='Visualizar o Car no Mapa'>
                <li key={ruralProperty.cod_imovel} id={ruralProperty.cod_imovel} className={styles.dataListCarItemFull} onClick={() => { handleRuralPropertyClick(ruralProperty.cod_imovel) }}>
                  <span className={styles.dataListItemLabel} >
                    <FlipToFrontIcon fontSize='small' />
                  </span>
                  <span className={styles.dataListItemLabel}>{ruralProperty.cod_imovel}</span>
                </li>
              </Tooltip>
            );

          })}
        </ul>
      </React.Fragment>
    );
  };

  return (
    <Fade in={isOpen && !loadingBaseData && !_.isEmpty(parsedData)}>
      <div className={classnames(styles.wrapper, {
        [styles.wrapperWithHeader]: headerIsVisible,
      })}>
        <header className={styles.header}>
          <h2 className={styles.title}><FormattedMessage id="mapbiomas.map.point_dialog.history_title" /></h2>
          <IconButton className={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </header>
        <div className={styles.chartWrapper}>
          <PointHistoryChart data={chartData} />
        </div>
        <h2 className={styles.title}><FormattedMessage id="mapbiomas.map.point_dialog.title" /></h2>
        <ul className={styles.dataList}>
          {_.map(parsedData, (item) => {

            return (
              <li key={item.id} id={item.id} className={styles.dataListItem}>
                <span className={styles.dataListItemLabel}>{item.label}</span>
                <span className={styles.dataListItemValue}>{item.value}</span>
              </li>
            );

          })}
          <li className={styles.dataListItem}>
            <span className={styles.dataListItemLabel}><FormattedMessage id="mapbiomas.map.point_dialog.data_list.coordinates" /></span>
            <span className={styles.dataListItemValue}>{formatNumber(_.first(latlng))}, {formatNumber(_.last(latlng))}</span>
          </li>
        </ul>
        {/* {renderCARs()} */}
      </div>
    </Fade>
  );
}
