import React, { Fragment, useEffect, useState } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Cleave from 'cleave.js/react';
import * as turf from '@turf/turf';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';

import { GET_BASE_DATA } from '../../../../query';
import toast from '../../../../../../utils/toast';

import styles from './CARBox.module.scss';

import {
  GET_POINT_DATA,
} from './query';

const API_URL =
  process.env.REACT_APP_GEE_API_URL ||
  'https://staging.api.mapbiomas.org/api/v1/brazil';

function fetchCARCodeLayerData(carCode) {
  return fetch(`${ API_URL }/maps/property/${ carCode }`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => response.json());
}

const useStyles = makeStyles((theme) => ({
  searchButtonActive: {
    backgroundColor: '#F1F1F1 !important',
    '& svg': {
      fill: `${theme.palette.primary.main} !important`,
    }
  }
}));

const CustomTooltipSimple = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 12,
    fontWeight: 400,
    color: 'white',
    backgroundColor: '#444444',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
  arrow: {
    color: '#444444'
  },
}))(Tooltip);

const initialState = {
  code: '',
};

function CARForm({
  isVisible,
  ruralPropertyCode,
  onFlyTo = () => { },
  onSearchPoint = () => { },
  onRuralPropertyCodeChange = () => { },
  updateClientAppState = () => { },
}) {

  const formatMessage = useFormatMessage();
  const [state, setState] = useState(initialState);
  const [isSearching, setIsSearching] = useState(false);
  

  useEffect(() => {
    setState(initialState);
  }, [isVisible]);

  const handleStateChange = (paramKey, paramValue) => {
    setState({
      ...state,
      [paramKey]: paramValue
    });
    // onRuralPropertyCodeChange(paramValue);
  };

  const handleTextChange = (paramKey, event) => {
    handleStateChange(paramKey, event.target.value);
  };

  useEffect(() => {
    if (ruralPropertyCode) {
      setIsSearching(true);
      
      fetchCARCodeLayerData(ruralPropertyCode)
        .then((data) => {
          setIsSearching(false);

          const feature = _.get(data, '[0]');

          if (feature) {
            const bbox = turf.bbox(feature);
            const parsedBBox = [
              [bbox[1], bbox[0]],
              [bbox[3], bbox[2]],
            ];

            onFlyTo(parsedBBox);

            const line = turf.lineString(parsedBBox)
            const center = turf.center(line);
            const coordinates = _.get(center, 'geometry.coordinates');
            const point = {
              lat: _.first(coordinates),
              lng: _.last(coordinates),
            };

            onSearchPoint({
              feature,
              point
            });
          } else {
            toast('Não foi possível localizar o CAR informado.', 'error');
          }
        });
    }
  }, [ruralPropertyCode]);

  const handleSubmit = (e) => {
    e.preventDefault();

    //setCode(state.code);

    const code = state.code;
    updateClientAppState({
      ruralPropertyCode: code
    });
  };

  return (
    <Fade in={isVisible}>
      <form className={styles.formWrapper} onSubmit={handleSubmit}>
        <FormControl fullWidth className={styles.formControl}>
          <TextField
            required
            id="code-field"
            label="Código do CAR"
            variant="outlined"
            size="small"
            value={state.code}
            onChange={handleTextChange.bind(this, 'code')}
          />
        </FormControl>
        {!isSearching &&
          <IconButton className={styles.searchIconButton} type="submit">
            <SearchIcon />
          </IconButton>
        }
        {isSearching &&
          <span className={styles.loadingProgressWrapper}>
            <CircularProgress size={20} color="secondary" />
          </span>
        }
      </form>
    </Fade>
  );
}

function CARInfo({
  data,
  headerIsVisible,
  showBeforeAndAfterMosaic,
  onClose = () => { handleClose() }
}) {
  return (
    <Fade in={!!data}>
      <div className={classnames(styles.carInfoWrapper, {
        [styles.carInfoWrapperWithoutHeader]: !headerIsVisible,
        [styles.carInfoWrapperWithBeforeAndAfterMosaic]: showBeforeAndAfterMosaic,
      })}>
        <header className={styles.header}>
          <h2 className={styles.title}>Informações do CAR</h2>
          <IconButton className={styles.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </header>
        <ul className={styles.dataList}>
          {_.map(data, (item) => {
            return (
              <li key={item.id} id={item.id} className={styles.dataListItem}>
                <span className={styles.dataListItemLabel}>{item.label}</span>
                <span className={styles.dataListItemValue}>{item.value}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </Fade>
  );
}

export default function CARBox({
  headerIsVisible,
  showBeforeAndAfterMosaic,
  onFlyTo = () => { },
  onFeatureChange = () => { },
  updateClientAppState = () => { }
}) {
  const locale = localStorage.getItem('locale') || 'es';
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const [showPointForm, setShowPointForm] = useState(false);
  const [CARData, setCARData] = useState(null);
  
  const { data: baseData } = useQuery(GET_BASE_DATA);
  const ruralPropertyCode = _.get(baseData, 'app.ruralPropertyCode');
  
  const [
    loadPointData,
    {
      data: pointData,
      loading: loadingPointData
    }
  ] = useLazyQuery(GET_POINT_DATA);

  useEffect(() => {
    if (!loadingPointData && pointData) {
      const getTerritoriesOnList = _.get(pointData, 'territoriesOnList');
      const territoryCategoriesList = _.get(pointData, 'territoryCategoriesList');
      const filteredTerritoryCategoriesList = _.filter(territoryCategoriesList, ({ parentId }) => !parentId);
      const parsedData = _(filteredTerritoryCategoriesList)
        .map((category) => {
          const territoryData = _.find(getTerritoriesOnList, { categoryId: category.id });

          if (territoryData) {
            const categoryStringList = _.get(category, 'i18nStrings');
            const categorySelectedString = _.find(categoryStringList, { language: locale });

            const territoryStringList = _.get(territoryData, 'i18nStrings');
            const territorySelectedString = _.find(territoryStringList, { language: locale });

            if (territoryData.categoryId == 15 || territoryData.categoryId == 16) {
              return null;
            } else {
              return {
                id: territoryData.id,
                category: territoryData.categoryId,
                category_key: category.key,
                label: _.get(categorySelectedString, 'stringValue'),
                value: _.get(territorySelectedString, 'stringValue'),
              };
            }
          } else {
            return null;
          }
        })
        .compact()
        .value();

      setCARData(parsedData);
      setShowPointForm(false);
    }
  }, [pointData, loadingPointData]);

  const handleClose = () => {
    setCARData(null);
    onFeatureChange(null);
  };

  const togglePointForm = () => {
    const isVisibile = !showPointForm;

    if (isVisibile) {
      setCARData(null);
    }

    setShowPointForm(isVisibile);
  };

  const handleSearchPoint = ({ feature, point }) => {
    if (point) {
      loadPointData({ variables: point });
      onFeatureChange(feature);
    }
  };

  return (
    <Fragment>
      <div className={classnames(styles.wrapper, {
        [styles.wrapperWithoutHeader]: !headerIsVisible,
        [styles.wrapperWithBeforeAndAfterMosaic]: showBeforeAndAfterMosaic,
      })}>
        {/* <div id="coordinates-search" className={styles.controlWrapper}>
          <CustomTooltipSimple arrow title="Localizar CAR" placement="right">
            <button
              className={classnames(styles.searchButton, {
                [classes.searchButtonActive]: showPointForm
              })}
              onClick={togglePointForm}
            >
              <FlipToFrontIcon />
            </button>
          </CustomTooltipSimple>
        </div> */}
        <CARForm
          isVisible={showPointForm}
          ruralPropertyCode={ruralPropertyCode}
          onFlyTo={onFlyTo}
          onSearchPoint={handleSearchPoint}
          onRuralPropertyCodeChange={(code) => {
            updateClientAppState({
              ruralPropertyCode: code
            });
          }}
          updateClientAppState={updateClientAppState}
        />
      </div>
      <CARInfo
        data={CARData}
        headerIsVisible={headerIsVisible}
        showBeforeAndAfterMosaic={showBeforeAndAfterMosaic}
        
        onClose={handleClose}
      />
    </Fragment>
  );
}
