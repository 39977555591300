import React from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/core/styles';

import { GET_BASE_DATA } from './query';

import BasePage from './layout/BasePage';

import MapView from './views/Map';

const mapThemePaletteByProduct = {
  'scenarios': {
    dark: '#3a5269',
    light: '#3a5269',
    main: '#34495e',
    contrastText: '#FFF'
  },
  'irrigation': {
    dark: '#ac2ec2',
    light: '#bb3dd1',
    main: '#b731ce',
    contrastText: '#FFF'
  },
  'fire': {
    dark: '#e5412e',
    light: '#e95949',
    main: '#e74c3c',
    contrastText: '#FFF'
  },
  'infrastructure': {
    dark: '#5c5c5c',
    light: '#5c5c5c',
    main: '#555',
    contrastText: '#FFF'
  },
  'regeneration': {
    dark: '#25a259',
    light: '#2abb67',
    main: '#27ae60',
    contrastText: '#FFF'
  },
  'deforestation': {
    dark: '#CD6130',
    light: '#CD6136',
    main: '#cd6133',
    contrastText: '#FFF'
  },
  'coverage': {
    dark: '#14947b',
    light: '#18af91',
    main: '#16a085',
    contrastText: '#FFF'
  },
  'quality_of_pasture_data': {
    dark: '#ef970c',
    light: '#f3a220',
    main: '#f39c12',
    contrastText: '#FFF'
  },
  'mining': {
    dark: '#ef970c',
    light: '#f3a220',
    main: '#f39c12',
    contrastText: '#FFF'
  },
  'coverage_quality': {
    dark: '#1c313a',
    light: '#718792',
    main: '#455a64',
    contrastText: '#FFF'
  },
  'temporal_analysis': {
    dark: '#4a0072',
    light: '#ae52d4',
    main: '#7b1fa2',
    contrastText: '#FFF'
  },
  'sentinel': {
    dark: '#0e1d35',
    light: '#344055',
    main: '#1f2942',
    contrastText: '#FFF'
  },
  'soil': {
    dark: '#6c5440',
    light: '#a0826d',
    main: '#886956',
    contrastText: '#FFF'
  },
  'degradation': {
    dark: '#845923',
    light: '#a36f2f',
    main: '#936327',
    contrastText: '#FFF'
  },
  'coral_reef': {
    dark: '#0b678c',
    light: '#0979a5',
    main: '#066F9A',
    contrastText: '#FFF'
  },
};

const defaultThemeColor = {
  dark: '#16a085',
  light: '#18af91',
  main: '#16a085',
  contrastText: '#FFF'
};

export default function LandingPage({
  history,
  location,
}) {
  const { data: baseData } = useQuery(GET_BASE_DATA);
  const activeModule = _.get(baseData, 'app.activeModule');
  const activeBaseMap = _.get(baseData, 'app.activeBaseMap') || 5;
  const activeLayers = _.get(baseData, 'app.activeLayers') ;
  const layersOpacity = _.get(baseData, 'app.layersOpacity');

  return (
    <ThemeProvider
      theme={(outerTheme) => ({
        ...outerTheme,
        palette: {
          ...outerTheme.palette,
          primary: mapThemePaletteByProduct[activeModule] || defaultThemeColor,
        },
      })}
    >
      <BasePage
        history={ history }
        location={ location }
      >
        <MapView
          activeBaseMap={ activeBaseMap }
          activeLayers={ activeLayers }
          layersOpacity={ layersOpacity }
          location={ location }
        />
      </BasePage>
    </ThemeProvider>
  )
}
