import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { useIntl, FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import BarChart from '../../../MapBiomasLandingPage/views/Map/components/StatisticsBox/components/BarChart';
import BaseValuesChart from '../../../MapBiomasLandingPage/views/Map/components/StatisticsBox/components/BaseValuesChart';

import getCustomClient from '../../../../lib/getCustomClient';

import styles from '../../TerritoryDashboard.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

export default function AnnualStatisticsContent({
  clientType,
  activeYear,
  baseParams,
  classTreeKey,
  territoryId,
  statisticsDataIsVisible = false,
  selectedClassTreeIds = [],
  onShowData = () => {},
  themeColor,
}) {
  const locale = localStorage.getItem('locale') || 'es';

  const intl = useIntl()

  const mainYear = _.last(activeYear);
  const years = [_.first(activeYear), _.last(activeYear)];
  // const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const territoryIds = [territoryId];

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: getCustomClient(clientType)
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  console.log('le classTreeLevelsList', classTreeLevelsList);
  let filteredActiveClassTreeNodeIds = _.map(_.filter(classTreeLevelsList, { level: 1 }), 'id');
  if (!_.isEmpty(selectedClassTreeIds)) {
    filteredActiveClassTreeNodeIds = _.filter(filteredActiveClassTreeNodeIds, (id) => _.includes(selectedClassTreeIds, id));
  }
  console.log('le filteredActiveClassTreeNodeIds', filteredActiveClassTreeNodeIds);
  const activeClassTreeNodeIds = filteredActiveClassTreeNodeIds;
  const activeParentClassTreeNodeIds = filteredActiveClassTreeNodeIds;
  console.log('filteredActiveClassTreeNodeIds', filteredActiveClassTreeNodeIds);
  // const filteredActiveClassTreeNodeIds = _.filter(activeClassTreeNodeIds, (nodeId) => {
  //   const nodeData = _.find(classTreeLevelsList, { id: nodeId });
  //   const childrenIds = _.get(nodeData, 'childrenIds');

  //   if (_.isEmpty(childrenIds)) {
  //     return true;
  //   } else {
  //     return !childrenIds.some(id => activeClassTreeNodeIds.indexOf(id) >= 0);
  //   }
  // });
  const getParentIds = (nodeId, ids = []) => {
    const nodeData = _.find(classTreeLevelsList, { id: nodeId });
    const parentId = _.get(nodeData, 'parentId');

    if (parentId) {
      return getParentIds(parentId, [...ids, parentId]);
    } else {
      return ids;
    }
  };
  // const activeParentClassTreeNodeIds = _.filter(activeClassTreeNodeIds, (nodeId) => {
  //   const parentIds = getParentIds(nodeId);
  //   const hasSomeParentActive = parentIds.some(id => activeClassTreeNodeIds.indexOf(id) >= 0);

  //   return !hasSomeParentActive;
  // });

  const { data: baseData, loading, error: errorBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      classTreeNodeIds: activeClassTreeNodeIds,
      filteredActiveClassTreeNodeIds: filteredActiveClassTreeNodeIds,
      activeParentClassTreeNodeIds: activeParentClassTreeNodeIds,
      years: years,
      territoriesIds: territoryIds
    },
    skip: loadingClassTreeData,
    client: getCustomClient(clientType)
  });

  const getTotalAreaByClassesData = () => {
    const data = _.get(baseData, 'getCalcAcumCoverageByClasses');
    const years = _.map(data, 'year');

    const classTreeNodeData = {};

    _.each(activeClassTreeNodeIds, (nodeId) => {
      _.each(years, (year) => {
        const yearData = _.find(data, { year });
        const nodeData = _.find(_.get(yearData, 'acumYear'), { classTreeNodeId: nodeId });
        const value = _.get(nodeData, 'total') || null;
        const nodeValues = _.get(classTreeNodeData, nodeId);

        if (nodeValues) {
          _.set(classTreeNodeData, nodeId, [...nodeValues, value]);
        } else {
          _.set(classTreeNodeData, nodeId, [value]);
        }
      });
    });

    const parsedSeries = _.map(_.keys(classTreeNodeData), (nodeId) => {
      const parsedNodeId = Number(nodeId);
      const nodeData = _.find(classTreeLevelsList, { id: parsedNodeId });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');
      const prettyIndexOfNodeInTree = _.join(_.get(nodeData, 'positionInTree'), '.');

      return {
        nodeId: parsedNodeId,
        stack: 'year',
        name: `${ prettyIndexOfNodeInTree }. ${ label }`,
        color: _.get(nodeData, 'color') || '#e74c3c',
        data: _.get(classTreeNodeData, nodeId),
      };
    });
    const sortedSeries = _.sortBy(parsedSeries, 'name');
    const filteredSeries = _.filter(sortedSeries, ({ nodeId }) => _.includes(filteredActiveClassTreeNodeIds, nodeId));

    return {
      categories: years,
      series: sortedSeries,
      filteredSeries,
    };
  };

  const getPercentageAreaByClasses = () => {
    const data = _.get(baseData, 'getCalcAcumCoverageByClassesFiltered');
    const years = _.map(data, 'year');

    const classTreeNodeData = {};

    _.each(filteredActiveClassTreeNodeIds, (nodeId) => {
      _.each(years, (year) => {
        const yearData = _.find(data, { year });
        const nodeData = _.find(_.get(yearData, 'acumYear'), { classTreeNodeId: nodeId });
        const value = _.get(nodeData, 'proporcionality') || null;
        const nodeValues = _.get(classTreeNodeData, nodeId);

        if (nodeValues) {
          _.set(classTreeNodeData, nodeId, [...nodeValues, value]);
        } else {
          _.set(classTreeNodeData, nodeId, [value]);
        }
      });
    });

    const parsedSeries = _.map(_.keys(classTreeNodeData), (nodeId) => {
      const parsedNodeId = Number(nodeId);
      const nodeData = _.find(classTreeLevelsList, { id: parsedNodeId });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');
      const prettyIndexOfNodeInTree = _.join(_.get(nodeData, 'positionInTree'), '.');

      return {
        nodeId: parsedNodeId,
        stack: 'year',
        name: `${ prettyIndexOfNodeInTree }. ${ label }`,
        color: _.get(nodeData, 'color') || '#e74c3c',
        data: _.get(classTreeNodeData, nodeId),
      };
    });
    const sortedSeries = _.sortBy(parsedSeries, 'name');

    return {
      categories: years,
      series: sortedSeries,
      filteredSeries: sortedSeries
    };
  };

  useEffect(() => {
    const sampleData = _.get(baseData, 'getCalcCoverageWichYears[0].maximun');

    if ((!loading && errorBaseData) || _.isNull(sampleData)) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && baseData) {
      setChartsData({
        'totalAreaByClasses': getTotalAreaByClassesData(),
        'percentageAreaByClasses': getPercentageAreaByClasses(),
      });
    }
  }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const areaByPeriod = () => {
    const data = _.get(baseData, 'getCalcCoverageWichYears[0]');
    const distByYears = _.get(data, 'distByYears') || [];
    const total = _.get(data, 'areaOccurrence') || 0;
    const maxValue = _.get(data, 'maximun') || 0;
    const maxYear = _.get(_.find(distByYears, { total: maxValue }), 'year');
    const averageValue = _.get(data, 'average') || 0;
    const minValue = _.get(data, 'minimun') || 0;
    const minYear = _.get(_.find(distByYears, { total: minValue }), 'year');
    const averagePercentage = (averageValue / maxValue) * 100;
    const minPercentage = (minValue / maxValue) * 100;

    const customTotal = _.get(_.find(distByYears, { year: mainYear }), 'total') || null;
    const isCustom = classTreeKey === 'mining_annual' ||
      classTreeKey === 'quality_of_pasture_main' ||
      classTreeKey === 'regeneration_annual_by_class' ||
      classTreeKey === 'irrigation_main' ||
      classTreeKey === 'deforestation_annual_by_class';

    return (
      <BaseValuesChart
        title={ !isCustom ? (intl.formatMessage({id: 'mapbiomas.statistics.area.period'})) : `${intl.formatMessage({id: 'mapbiomas.statistics.area.title'})} - ${ mainYear }` }
        totalTitle="Total"
        total={ !isCustom ? total : customTotal }
        maxValue={ maxValue }
        maxYear={ maxYear }
        averageValue={ averageValue }
        averagePercentage={ averagePercentage }
        minValue={ minValue }
        minYear={ minYear }
        minPercentage={ minPercentage }
        color={ themeColor }
      />
    );
  };

  const totalAreaByClasses = () => {
    const categories = _.get(chartsData, 'totalAreaByClasses.categories') || [];
    const series = _.get(chartsData, 'totalAreaByClasses.filteredSeries') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.anual_statistics_content.area_title" /></h2>
        <BarChart
          categories={ categories }
          series={ series }
        />
      </div>
    );
  };

  const percentageAreaByClasses = () => {
    const categories = _.get(chartsData, 'percentageAreaByClasses.categories') || [];
    const series = _.get(chartsData, 'percentageAreaByClasses.filteredSeries') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.anual_statistics_content.proportion_title" /></h2>
        <BarChart
          isPercentage
          categories={ categories }
          series={ series }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const totalAreaByClassesChartData = _.get(chartsData, 'totalAreaByClasses');
    const totalAreaByClassesChartDataCategories = _.get(totalAreaByClassesChartData, 'categories') || [];
    const totalAreaByClassesChartDataSeries = _.get(totalAreaByClassesChartData, 'series') || [];

    const totalAreaByClassesChart = {
      title: <FormattedMessage id="mapbiomas.anual_statistics_content.area_title" />,
      columns: totalAreaByClassesChartDataCategories,
      rows: totalAreaByClassesChartDataSeries
    };

    const percentageAreaByClassesChartData = _.get(chartsData, 'percentageAreaByClasses');
    const percentageAreaByClassesChartDataCategories = _.get(percentageAreaByClassesChartData, 'categories') || [];
    const percentageAreaByClassesChartDataSeries = _.get(percentageAreaByClassesChartData, 'series') || [];

    const percentageAreaByClassesChart = {
      title: <FormattedMessage id="mapbiomas.anual_statistics_content.proportion_title" />,
      columns: percentageAreaByClassesChartDataCategories,
      rows: percentageAreaByClassesChartDataSeries
    };

    onShowData([
      totalAreaByClassesChart,
      percentageAreaByClassesChart
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 6 }></Grid>
          <Grid item xs={ 6 }>
            { areaByPeriod() }
          </Grid>
          <Grid item xs={ 12 }>
            { totalAreaByClasses() }
          </Grid>
        </Grid>
        {/* { percentageAreaByClasses() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div> */}
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
