import _ from 'lodash';

function parseTerritories(territories) {
  return _.map(territories, (territory) => {
    const {
      id,
      label,
      territoryTypeId,
      territoryTypeLabel,
      territoryBBox,
    } = territory;
    const bboxV1 = territoryBBox[0][0];
    const bboxV2 = territoryBBox[0][1];
    const bboxV3 = territoryBBox[1][0];
    const bboxV4 = territoryBBox[1][1];

    return `${ _.join([id, label, territoryTypeId, territoryTypeLabel, bboxV1, bboxV2, bboxV3, bboxV4], ';') }`;
  });
}

export default function parseStateToQueryParams(state) {
  const {
    activeBaseMap,
    activeLayers,
    layersOpacity,
    activeModule,
    activeModuleContent,
    activeYear,
    mapPosition,
    showBeforeAndAfterMosaic,
    timelineLimitsRange,
    baseParams,
  } = state || {};

  const {
    groupType,
    territoryType,
    territorySubtype,
    territoryValueType,
    crossingEnabled,
    crossingTerritoryType,
    crossingTerritories,
    territory,
    territories,
    crossing,
    classesViewMode,
    activeClassTreeOptionValue,
    activeClassTreeNodeIds,
    activeObjectTreeNodeIds,
    activeSubmodule,
    activeClassesLevelsListItems,
    activeTransitionsClassesListItems,
    activePastureQualityQualityClassesListItems,
    yearRange,
    buffer,
  } = baseParams || {};

  let queryParams = {
    activeBaseMap,
    layersOpacity,
    activeModule,
    activeModuleContent,
    activeYear,
    mapPosition,
    timelineLimitsRange,
  };

  if (!_.isEmpty(activeLayers)) {
    _.set(queryParams, 'activeLayers', activeLayers);
  }

  if (showBeforeAndAfterMosaic) {
    _.set(queryParams, 'showBeforeAndAfterMosaic', showBeforeAndAfterMosaic);
  }

  if (groupType && groupType !== 'territorial') {
    _.set(queryParams, 'baseParams.groupType', groupType);
  }

  if (territoryType && territoryType !== null) {
    _.set(queryParams, 'baseParams.territoryType', territoryType);
  }

  if (crossingTerritoryType && crossingTerritoryType !== null) {
    _.set(queryParams, 'baseParams.crossingTerritoryType', crossingTerritoryType);
  }

  if (territorySubtype && territorySubtype !== null) {
    _.set(queryParams, 'baseParams.territorySubtype', territorySubtype);
  }

  if (territoryValueType && territoryValueType !== 'single') {
    _.set(queryParams, 'baseParams.territoryValueType', territoryValueType);
  }

  if (crossingEnabled) {
    _.set(queryParams, 'baseParams.crossingEnabled', crossingEnabled);
  }

  if (territory && territory !== null) {
    _.set(queryParams, 'baseParams.territory', territory);
  }

  if (territories && territories !== null) {
    const parsedTerritories = parseTerritories(territories);

    _.set(queryParams, 'baseParams.territories', parsedTerritories);
  }

  if (crossingTerritories && crossingTerritories !== null) {
    const parsedTerritories = parseTerritories(crossingTerritories);

    _.set(queryParams, 'baseParams.crossingTerritories', parsedTerritories);
  }

  if (crossing && crossing !== 'none') {
    _.set(queryParams, 'baseParams.crossing', crossing);
  }

  if (classesViewMode && classesViewMode !== 'class') {
    _.set(queryParams, 'baseParams.classesViewMode', classesViewMode);
  }

  if (activeClassTreeOptionValue && activeClassTreeOptionValue !== null) {
    _.set(queryParams, 'baseParams.activeClassTreeOptionValue', activeClassTreeOptionValue);
  }

  if (activeClassTreeNodeIds && !_.isEmpty(activeClassTreeNodeIds)) {
    _.set(queryParams, 'baseParams.activeClassTreeNodeIds', activeClassTreeNodeIds);
  }

  if (activeObjectTreeNodeIds && !_.isEmpty(activeObjectTreeNodeIds)) {
    _.set(queryParams, 'baseParams.activeObjectTreeNodeIds', activeObjectTreeNodeIds);
  }

  if (activeSubmodule && activeSubmodule !== null) {
    _.set(queryParams, 'baseParams.activeSubmodule', activeSubmodule);
  }

  if (activeClassesLevelsListItems && activeClassesLevelsListItems !== null) {
    _.set(queryParams, 'baseParams.activeClassesLevelsListItems', activeClassesLevelsListItems);
  }

  if (activeTransitionsClassesListItems && activeTransitionsClassesListItems !== null) {
    _.set(queryParams, 'baseParams.activeTransitionsClassesListItems', activeTransitionsClassesListItems);
  }

  if (activePastureQualityQualityClassesListItems && activePastureQualityQualityClassesListItems !== null) {
    _.set(queryParams, 'baseParams.activePastureQualityQualityClassesListItems', activePastureQualityQualityClassesListItems);
  }

  if (yearRange && yearRange !== '1985-2023') {
    _.set(queryParams, 'baseParams.yearRange', yearRange);
  }

  if (buffer && buffer !== 0) {
    _.set(queryParams, 'baseParams.buffer', buffer);
  }

  return queryParams;
}
