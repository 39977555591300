export default {
  coverage: {
    activeModule: 'coverage',
    activeModuleContent: 'coverage:coverage_main',
    activeYear: 2023,
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  temporal_analysis: {
    activeModule: 'temporal_analysis',
    activeModuleContent:
      'temporal_analysis:temporal_analysis_number_of_classes',
    activeYear: [1985, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  coverage_quality: {
    activeModule: 'coverage_quality',
    activeModuleContent: 'coverage_quality:coverage_quality_main',
    activeYear: 2023,
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  quality_of_pasture_data: {
    activeModule: 'quality_of_pasture_data',
    activeModuleContent: 'quality_of_pasture_data:quality_of_pasture_data_main',
    activeYear: [2000, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [2000, 2023],
    mapboxMode: false,
  },
  irrigation: {
    activeModule: 'irrigation',
    activeModuleContent: 'irrigation:irrigation_main',
    activeYear: [1985, 2022],
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2022],
    mapboxMode: false,
  },
  deforestation: {
    activeModule: 'deforestation',
    activeModuleContent: 'deforestation:deforestation_annual',
    activeYear: [1986, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1986, 2023],
    mapboxMode: false,
  },
  regeneration: {
    activeModule: 'regeneration',
    activeModuleContent: 'regeneration:regeneration_annual',
    activeYear: [1986, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1986, 2023],
    mapboxMode: false,
  },
  infrastructure: {
    activeModule: 'infrastructure',
    activeModuleContent: 'infrastructure:infrastructure_main',
    activeYear: 2020,
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2020],
    mapboxMode: false,
  },
  fire: {
    activeModule: 'fire',
    activeModuleContent: 'fire:fire_accumulated',
    activeYear: [1985, 2022],
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2022],
    mapboxMode: false,
  },
  mining: {
    activeModule: 'mining',
    activeModuleContent: 'mining:mining_main',
    activeYear: [1985, 2023],
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2023],
    mapboxMode: false,
  },
  soil: {
    activeModule: 'soil',
    activeModuleContent: 'soil:soil_main',
    activeYear: 2021,
    timelineSpecificValues: null,
    timelineLimitsRange: [1985, 2021],
    mapboxMode: false,
  },
  sentinel: {
    activeModule: 'sentinel',
    activeModuleContent: 'sentinel:sentinel_coverage',
    activeYear: 2022,
    timelineSpecificValues: null,
    timelineLimitsRange: [2016, 2022],
    mapboxMode: false,
  },
  degradation: {
    activeModule: 'degradation',
    activeModuleContent: 'degradation:degradation_fragmentation',
    activeYear: 2021,
    timelineSpecificValues: null,
    timelineLimitsRange: [1986, 2021],
    mapboxMode: false,
  },
};
