export default [
  {
    key: 'country',
    labelKey: 'country',
    color: '#222222',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'IGM (Ajustado por Mapbiomas Uruguay)',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2023',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_2/download/layers/POLITICAL_LEVEL_1.zip',
    index: 1,
  },
  {
    key: 'department',
    labelKey: 'department',
    color: '#FDBF6F',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'IGM (Ajustado por Mapbiomas Uruguay)',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2023',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_2/download/layers/POLITICAL_LEVEL_2.zip',
    index: 2,
  },
  {
    key: 'watershed_otto_1',
    labelKey: 'watershed_otto_1',
    color: '#55F8F8',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'DINAGUA',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2022',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_2/download/layers/BASIN_LEVEL_1.zip',
    index: 3,
  },
  {
    key: 'watershed_otto_2',
    labelKey: 'watershed_otto_2',
    color: '#130BE3',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'DINAGUA',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2022',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_2/download/layers/BASIN_LEVEL_2.zip',
    index: 4,
  },
  {
    key: 'ecoregions',
    labelKey: 'ecoregions',
    color: '#E000FF',
    descriptionData: [
      {
        labelKey: 'source',
        label: 'Fonte',
        value: 'Panario et al.',
      },
      {
        labelKey: 'year',
        label: 'Ano',
        value: '2015',
      },
    ],
    downloadUrl:
      'https://storage.googleapis.com/mapbiomas-public/initiatives/uruguay/collection_2/download/layers/ECORREGION.zip',
    index: 5,
  },
];
